<template>
  <div class="CommentCreator">
    <AutoList />
    <div class="page">
      <div class="header">
        <span class="title">
          {{ this.id != 0 ? "修改自動回復" : "創建自動回復" }}
        </span>
      </div>
      <div class="container" v-loading="loading">
        <el-scrollbar>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="form"
            label-position="top"
            size="small"
          >
            <el-form-item label="關鍵字" prop="word">
              <el-input v-model="ruleForm.word"></el-input>
            </el-form-item>
            <el-form-item label="內容" prop="content">
              <el-input v-model="ruleForm.content"></el-input>
            </el-form-item>
            <el-form-item label="狀態" prop="status">
              <el-radio v-model="ruleForm.status" :label="1">啟用</el-radio>
              <el-radio v-model="ruleForm.status" :label="0">停用</el-radio>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import AutoList from "../List";

export default {
  name: "AutoCreator",

  components: {
    AutoList,
  },

  watch: {
    "$route.params.id"() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      id: null,
      ruleForm: {
        word: "",
        content: "",
        status: 1,
      },
      rules: {
        word: [
          { required: true, message: "請輸入關鍵字", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "長度在 2 到 20 個字串",
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入自動回復內容", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },

  methods: {
    init() {
      this.resetForm("ruleForm");

      this.id = this.$route.params.id;

      if (this.id != 0) {
        this.getAuto();
      }
    },

    async getAuto() {
      this.loading = true;

      const result = await this.$fetch("auto/" + this.id);
      if (!result.isOk) {
        this.$message.error(result.message);
        return;
      }

      this.ruleForm = result.data;
      this.loading = false;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    async submit() {
      let isValid = false;

      this.$refs["ruleForm"].validate((valid) => {
        if (!valid) {
          this.$message.error("填寫資料錯誤，請檢查");
          return;
        }

        isValid = true;
      });

      if (!isValid) {
        return;
      }

      this.loading = true;

      const result = await this.$fetch(
        "auto" + (this.id != "0" ? "/" + this.id : ""),
        {
          method: this.id != "0" ? "PUT" : "POST",
          body: this.ruleForm,
        }
      );

      if (!result.isOk) {
        this.$message.error(result.message);
        return;
      }

      this.$message.success("操作成功");
      this.$store.commit("auto/update", Math.random());

      this.loading = false;
    },
  },
};
</script>